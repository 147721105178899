

.conditions {
  .ant-typography {
    text-align: center;
  }
  &__content {
    * {
      color: #fff !important ;
    }
  }
}
